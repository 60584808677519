import React, {useState} from 'react';
import {Box, Flex, useTheme} from '@chakra-ui/react';

import {useNavigate} from 'react-router-dom';
import {Offer, Place} from '../../types/acrticle';
import {H3, TextG10, TextG11, TextG12} from '../../components/typography';
import useBookmark from '../../hooks/useBookmark';
import Icon from '../../components/Icon';
import DotSeparator from '../../components/DotSeparator';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';
import useGetUserLocation from '../../hooks/useUserLocation';
import {dollarsToPoints, getPointsToDollarRatio, numberToCurrency} from '../../utils/price';
import {BlueButton} from '../../components/button';
import UseablePassPill from '../../components/Articles/Offers/OfferCard/UseablePassPill';
import useAuth from '../../hooks/useAuth';
import NotEnoughPointsModal from './NotEnoughPointsModal';

const Header = ({
  item,
  place,
  showBookmark = true,
}: {
  item: Offer;
  place?: Place;
  showBookmark?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(item, 'offer');
  const userLocation = useGetUserLocation();
  const {user} = useAuth();

  const [showModal, setShowModal] = useState(false);

  const discountAmt = user?.activeMembershipPlan?.event_discount;
  const discountPrice =
    item.retail_price && +item?.retail_price - (+item?.retail_price * discountAmt) / 100;

  const pointCostNum =
    discountAmt && discountAmt > 0
      ? Math.ceil(dollarsToPoints(discountPrice, getPointsToDollarRatio(item)))
      : item.points_redeem_amount;

  const pointCost = pointCostNum.toLocaleString();

  const userPoints = user?.pointsTotal;

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <Flex
      //minH="200px"
      position="relative"
      top="0"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-45px"
      marginBottom="-5rem"
      backgroundColor="white"
      boxShadow="med"
      //boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      pb="16px"
      flexDirection={'column'}>
      <Flex width="100%" flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <H3>{item.title}</H3>
          {showBookmark && (
            <Flex minW="60px" direction="column" align="flex-end" onClick={handleOnBookmarkClick}>
              {isBookmarked ? (
                <Icon iconName="fi-sr-bookmark" style={iconStyle} />
              ) : (
                <Icon iconName="fi-rr-bookmark" style={iconStyle} />
              )}
            </Flex>
          )}
        </Flex>
        <Box mb={2}>
          <UseablePassPill item={item} />
        </Box>
        {place && (
          <Flex alignItems="center">
            <>
              <TextG12
                color={theme.colors.blue}
                onClick={() => navigate(`/place/${item.place_id}`)}>
                <span>Pick Up @ </span>
                <Box as="span" fontWeight="bold">
                  {place.name}
                </Box>
              </TextG12>
            </>
            {userLocation.data?.latitude &&
              userLocation.data?.longitude &&
              place?.latitude &&
              place?.longitude && (
                <Flex alignItems="center" color={theme.colors.grey}>
                  <DotSeparator color={theme.colors.grey} />
                  <TextG12 whiteSpace="nowrap">
                    {getDistanceString(
                      calculateAndGetDistance(
                        {
                          latitude: userLocation.data.latitude,
                          longitude: userLocation.data.longitude,
                        },
                        {
                          latitude: Number(place.latitude),
                          longitude: Number(place.longitude),
                        }
                      )
                    )}
                  </TextG12>
                </Flex>
              )}
          </Flex>
        )}
        <Flex
          w="100%"
          justifyContent="flex-end"
          alignItems="center"
          color={theme.colors.lightBlack}
          mt="14px"
          gap="5px">
          {(!item.retail_price || +item.retail_price <= 0) &&
          (!item.points_redeem_amount || item.points_redeem_amount <= 0) ? (
            <TextG12 fontWeight="500">Free</TextG12>
          ) : (
            <>
              {Boolean(item.retail_price && item.item_payment_cc && +item.retail_price > 0) && (
                <>
                  {discountAmt && discountAmt > 0 ? (
                    <>
                      <Box pos="relative" display="inline-block">
                        <Box
                          mx="3px"
                          pos="absolute"
                          top="60%"
                          left="-3px"
                          right="-3px"
                          transform="skewY(-15deg)"
                          borderBottom="1px solid"
                          borderColor="gray.500"
                          pointerEvents="none"
                        />
                        <TextG11 fontWeight="500" color="gray.500">
                          {numberToCurrency(item.retail_price)}
                        </TextG11>
                      </Box>
                      <TextG12 fontWeight="500">{numberToCurrency(discountPrice)}</TextG12>
                    </>
                  ) : (
                    <TextG12 fontWeight="500">{numberToCurrency(item.retail_price)}</TextG12>
                  )}
                </>
                // <TextG12 fontWeight="500">{numberToCurrency(offer.retail_price)}</TextG12>
              )}
              {Boolean(
                item.retail_price &&
                  item.points_redeem_amount &&
                  +item.retail_price > 0 &&
                  item.points_redeem_amount > 0 &&
                  item.item_payment_cc &&
                  item.item_payment_points
              ) && <TextG10>or</TextG10>}
              {Boolean(
                item.points_redeem_amount &&
                  item.item_payment_points &&
                  item.points_redeem_amount > 0
              ) && (
                <>
                  {
                    <TextG12 color={theme.colors.rose} fontWeight="500">
                      <span style={{fontWeight: '600'}}>{pointCost}</span> Points
                    </TextG12>
                  }
                </>
              )}
            </>
          )}
        </Flex>
        <BlueButton
          mt="20px"
          onClick={() => {
            if (
              Boolean(
                item.points_redeem_amount &&
                  item.item_payment_points &&
                  item.points_redeem_amount > 0 &&
                  !item.item_payment_pass &&
                  !item.item_payment_cc
              ) &&
              pointCostNum > (userPoints || 0)
            ) {
              setShowModal(true);
              if (!isBookmarked) {
                handleOnBookmarkClick(null);
              }
            } else {
              navigate(`/item/${item.id}/purchase`);
            }
          }}>
          I Want This!
        </BlueButton>
      </Flex>
      <NotEnoughPointsModal isOpen={showModal} setIsOpen={setShowModal} />
    </Flex>
  );
};

export default Header;
