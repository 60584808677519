import {Box, Flex, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TextA10, TextA18, TextA8, TextA9, TextG12, TextG14} from '../../../components/typography';
import PaymentOptions from '../PaymentOptions';
import Header from './Header';
import OrderDetails from '../OrderDetails';
import {BlueButton} from '../../../components/button';
import {useSelector} from 'react-redux';
import {dollarsToPoints, getPointsToDollarRatio, numberToCurrency} from '../../../utils/price';
import {Event, Offer} from '../../../types/acrticle';
import useAuth from '../../../hooks/useAuth';

interface CheckoutDetailsProps {
  setShowSummary: any;
  ticket?: any;
  pass?: any;
  event?: Event;
  type?: string;
  item?: Offer | any;
  eligibleUserPasses?: any;
  pointCost?: string;
  retailCost?: any;
  passType?: any;
  cardEnabled?: boolean;
  pointsEnabled?: boolean;
  passEnabled?: boolean;
}

const CheckoutDetails = ({
  setShowSummary,
  event,
  item,
  type,
  eligibleUserPasses,
  pointCost,
  retailCost,
  passType,
  cardEnabled,
  pointsEnabled,
  passEnabled,
}: CheckoutDetailsProps) => {
  const theme = useTheme();
  const [highlightedOptionId, setHighlightedOptionId] = useState<string | undefined>();
  const {amountDue, originalTotal} = useSelector((state: any) => state.checkout);

  const {user} = useAuth();
  const discPerc = user?.activeMembershipPlan?.event_discount;

  return (
    <>
      <Header type={type} />
      <OrderDetails item={item} />
      <VStack align="flex-start" px={4} py={6}>
        <VStack align="flex-start" w="100%">
          <>
            <Flex w="100%" justifyContent="space-between">
              <TextA18 transition="all ease 0.3s" fontWeight="700">{`Retail Price:`}</TextA18>
              <TextA18
                transition="all ease 0.3s"
                fontWeight="700">{`$${item.retail_price}`}</TextA18>
            </Flex>

            {item.retail_value && (
              <Flex w="100%" justifyContent="space-between" mt="0 !important">
                <TextA10
                  color="#757D84"
                  transition="all ease 0.3s"
                  fontWeight="700">{`(Retail Value: $${item.retail_value})`}</TextA10>
               </Flex>
            )}

            <Flex w="100%" justifyContent="space-between">
              <TextA18
                transition="all ease 0.3s"
                fontWeight="700"
                color={amountDue > 0 ? theme.colors.red : theme.colors.darkGrey}>
                {`Total Amount Due:`}
              </TextA18>
              <TextA18
                transition="all ease 0.3s"
                fontWeight="700"
                color={amountDue > 0 ? theme.colors.red : theme.colors.darkGrey}>{` ${
                !item.item_payment_cc && !item.item_payment_pass && item.item_payment_points
                  ? `${dollarsToPoints(
                      amountDue,
                      getPointsToDollarRatio(item)
                    ).toLocaleString()} Points`
                  : numberToCurrency(amountDue)
              }`}</TextA18>
            </Flex>
            {discPerc && (
              <TextG12
                marginTop="8px !important"
                fontWeight="500"
                color="#757D84">{`(We've applied your membership discount of ${discPerc}%)`}</TextG12>
            )}
            <TextG12 marginTop="8px !important" fontWeight="500" color="#242629">
              Select which method(s) you'd like to use to check out
            </TextG12>
          </>

          <PaymentOptions
            cardEnabled={cardEnabled}
            pointsEnabled={pointsEnabled}
            passEnabled={passEnabled}
            passType={passType}
            pointCost={pointCost}
            retailCost={retailCost}
            eligibleUserPasses={eligibleUserPasses}
            highlightedOptionId={highlightedOptionId}
            setHighlightedOptionId={setHighlightedOptionId}
            event={event}
            type={type}
          />
        </VStack>
      </VStack>
      <Box
        onClick={() => {
          if (amountDue <= 0) {
            setShowSummary(true);
          }
        }}
        w="100%"
        p={4}
        pos="fixed"
        bottom="0"
        background="white">
        <BlueButton isDisabled={amountDue > 0} w="100%">
          <TextG14>Continue</TextG14>
        </BlueButton>
      </Box>
    </>
  );
};

export default CheckoutDetails;
