import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import React from 'react';
import {BlueButton} from '../../components/button';
import {TextA24, TextG14} from '../../components/typography';
const NotEnoughPointsModal = ({isOpen, setIsOpen}) => {
  const handleOnClose = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} autoFocus={false} returnFocusOnClose={false}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.48)'} />
      <ModalContent boxShadow="none" mt={36}>
        <ModalHeader pt={8}>
          <TextA24 textAlign="center" fontWeight="900">
            Not Enough Points!
          </TextA24>
        </ModalHeader>
        <ModalCloseButton zIndex={1} _focus={{}} />
        <ModalBody>
          <TextG14 textAlign="center">
            You don't have enough points for this Item. We went ahead and bookmarked the item for
            you. Click 'My Stuff' on the dock bar to find your bookmarks.
          </TextG14>
        </ModalBody>
        <ModalFooter pt={3} mb="16px">
          <BlueButton h="45px" w="100%" onClick={handleOnClose}>
            <TextG14 textAlign="center">Okay</TextG14>
          </BlueButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotEnoughPointsModal;
