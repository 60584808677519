import {AbsoluteCenter, VStack, Flex, useTheme, Box, Divider, Image} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {SpinnerLoader} from '../../../components/Loader';
import {TextG12} from '../../../components/typography';
//import Header from '../Header';
import Background from '../../../assets/images/ticketBG.png';
import {BlueButton} from '../../../components/button';
import {useFetchOffer} from '../../../hooks/useOffers';
import Header from './Header';
import ItemPurchaseQtyContainer from './ItemPurchaseQtyContainer';
import OfferCard from '../../../components/Articles/Offers/OfferCard';
import useAuth from '../../../hooks/useAuth';
import {useFetchActivePasses} from '../../../hooks/useUserPasses';
import {filter, some} from 'lodash';

const PassPurchase = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();

  const [quantity, setQuantity] = useState(1);

  const {itemId} = useParams();
  const [searchParams] = useSearchParams();
  const {data, isLoading} = useFetchOffer(itemId || searchParams.get('id'));
  const item = data?.data;

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  // const userPassesIds = map(
  //   filter(data?.data, (item) => item.pass !== null),
  //   (item) => item.pass?.type_id
  // );
  //const passIds = map(item?.pass_types, (item) => item.id);

  //const userHasPass = passIds.some((id) => userPassesIds.includes(id));

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(item?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );

  if (isLoading || !item)
    return (
      <AbsoluteCenter>
        <SpinnerLoader />
      </AbsoluteCenter>
    );

  return (
    <Flex flexDir="column">
      <Header item={item} />
      <VStack background={theme.colors.veryLightBlue} px="16px">
        <OfferCard offer={item} />
        <ItemPurchaseQtyContainer item={item} quantity={quantity} setQuantity={setQuantity} />
      </VStack>
      {user?.hasActiveMembership && eligibleUserPasses && eligibleUserPasses.length > 0 && item && (
        <Flex
          alignItems="baseline"
          borderRadius="12px"
          m={4}
          py={4}
          px={6}
          flexDir="column"
          background="white"
          border="1px solid #D2D9DF">
          <TextG12 fontWeight="700" color="#329241">
            <i
              className="fi fi-br-check"
              style={{fontSize: '10px', color: '#329241', marginRight: '0.3rem'}}></i>{' '}
            You can use an experience pass to save money on this item!
            <br />
            <br />
            <TextG12 fontWeight="700" color="#757D84">
              Select 'Use an Experience Pass' on the next screen to apply it during checkout.
            </TextG12>
          </TextG12>
        </Flex>
      )}

      {!item.item_payment_cc && !item.item_payment_pass && (
        <Flex
          alignItems="baseline"
          borderRadius="12px"
          m={4}
          py={4}
          px={6}
          flexDir="column"
          background="white"
          border="1px solid #D2D9DF">
          <TextG12 fontWeight="700" color="#329241">
            <i
              className="fi fi-br-check"
              style={{fontSize: '10px', color: '#329241', marginRight: '0.3rem'}}></i>
            You can buy this item with your Walkabout Points!
            <br />
            <br />
          </TextG12>
          <TextG12 fontWeight="700" color="#757D84">
            An option to apply your points to this item will appear on the next screen.
          </TextG12>
        </Flex>
      )}
      <Image zIndex="-1" pos="relative" top="-75px" w="100%" src={Background} />
      <Flex
        background="white"
        pos="fixed"
        bottom="0px"
        justify="center"
        margin="0px !important"
        px={4}
        py={6}
        w="100%">
        <BlueButton
          w="100%"
          onClick={() =>
            navigate(`/checkout?qty=${quantity}&item=${item.id}&type=item`, {replace: true})
          }>
          Continue to Checkout
        </BlueButton>
      </Flex>
    </Flex>
  );
};

export default PassPurchase;
