import FacebookIcon from '../assets/images/facebook.png';
import InstagramIcon from '../assets/images/instagram.png';
import TwitterIcon from '../assets/images/twitter.png';
import RSSIcon from '../assets/images/rss.png';

import {User} from '../hooks/useAuth';
import {PLACE_STATUS} from '../constants/openingHours';
import {Community} from './community';
interface Attribute {
  id: number;
  title: string;
  icon: string;
}

export interface TopicOfInterest {
  id: number;
  category_id: number;
  created_at: string;
  description: string;
  icon: string;
  name: string;
}

export const PTMap = new Map<string, string>([['coffee-shop', 'Coffe Shop']]);

export type OpeningHours = [string, string] | [string] | [];

export enum ARTICLE_TYPES {
  ALL = 'all',
  EVENT = 'event',
  POST = 'post',
  PLACE = 'place',
}

export interface DaysOfTheWeek {
  monday: OpeningHours;
  tuesday: OpeningHours;
  wednesday: OpeningHours;
  thursday: OpeningHours;
  friday: OpeningHours;
  saturday: OpeningHours;
  sunday: OpeningHours;
  exceptions: Record<string, [string, string]>;
}

export interface PlaceType {
  id: number;
  category: string;
  name: string;
  display_name: string;
  created_at: string;
  updated_at: string;
}

export type BusinessHours = {
  id: number;
  place_id: number;
  periods?: [];
  weekday_text?: string[];
};

export type ArticleMedia = {
  id: number;
  place_id: number;
  original_name: string;
  mime_type: string;
  path: string;
  full_url: string;
  is_default: boolean;
};

export interface Partner {
  id: number;
  uuid: string;
  name: string;
  website: string;
  description: string;
  content: string;
  media: ArticleMedia[];
  places: Place[];
  events: Event[];
  offers: Offer[];
  articles: Post[];
  place_type_id: number;
}

export interface Place extends Article {
  partner: any;
  media: ArticleMedia[];
  website: string;
  default_image: string;
  name: string;
  articles: Post[];
  attributes: Attribute[];
  business_hours?: BusinessHours;
  business_status?: string;
  email: string;
  phone: string;
  info: unknown;
  location: string;
  street_address: string;
  city: string;
  state: string;
  zipcode: string;
  title: string;
  opening_hours: DaysOfTheWeek | string;
  status: number;
  updated_at: string;
  endorsements: Upvote[];
  endorsements_counted: {
    icon: string;
    id: number;
    name: string;
    total: number;
    active?: boolean;
  }[];
  endorsements_approved_count: number;
  tags_counted: Tag[];
  image_url: string;
  type: PlaceType | string;
  place_type_id: number;
  distance: number;
  is_checked_in: boolean;
  is_endorsed: boolean;
  checkins_counted: number;
  price: string;
  longitude: number;
  latitude: number;
  events: Event[];
  topics_of_interest: TopicOfInterest[];
  offers: Offer[];
  open_status?: PLACE_STATUS;
  partner_id?: number;
}

export interface Event extends Article {
  longitude: string;
  latitude: string;
  name: string;
  content: string;
  place?: Place;
  articles: Article[];
  attributes: Attribute[];
  business_hours?: [];
  email: string;
  info: unknown;
  location: string;
  opening_hours?: DaysOfTheWeek;
  start_date_time: string;
  end_date_time: string;
  title: string;
  type: 'event';
  tickets_url: string;
  event_attendance: any[];
  price: number;
  max_price: number | null;
  price_override: string | null;
  topics_of_interest: TopicOfInterest[];
  is_recurring: boolean;
  recurring_schedule: string;
  attendance: string;
  rsvp_status: AttendanceRSVP;
  has_checked_in: boolean;
  allow_check_in: boolean;
  reward_with_points?: number;
  unlocked_offer?: {
    checkin_id: number;
    created_at: string;
    updated_at: string;
    event_id: number;
    id: number;
    offer: Offer;
    offer_id: number;
    user_id: number;
  };
  unlocks_offer_id?: number;
  allow_early_check_in?: boolean;
  is_walkabout_official: boolean;
  reason?: string;
  is_meetup?: boolean;
  available_tickets?: any[];
  ticket_type_id?: number;
  pass_types?: any[];
  has_tickets?: boolean;
  place_id?: number;
}

export interface AttendanceRSVP {
  status: null | string;
  status_value: null | number;
}

export enum EventAttendanceStatus {
  YES = 'Yes',
  NO = 'No',
  MAYBE = 'Maybe',
}

export interface SocialNetwork {
  id: number;
  name: keyof typeof socialNetworkIconMapper;
  display_name: string;
}

export interface SocialAccount {
  social_network: SocialNetwork;
  full_name: string;
  username: string;
  profile_image_url: string;
}

export interface Post extends Article {
  title: string;
  content: string;
  place?: Place;
  event?: Event;
  location: string;
  tags: Tag[];
  social_account: SocialAccount;
  type: 'post';
  topics_of_interest: TopicOfInterest[];
}

export interface Article {
  id: number;
  description: string;
  image_url: string;
  is_bookmarked: boolean;
  updated_at: string;
  created_at: string;
  released_at: string;
  user?: User;
  user_id: number;
  status: number;
  model_type: string;
  is_premium?: boolean;
  is_private?: boolean;
  communities?: Community[];
}

export type ArticleType = Post | Event | Place | any;

export interface Tag {
  icon: string;
  id: number;
  name: string;
  total?: number;
}

export const socialNetworkIconMapper = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
  rss: RSSIcon,
};

export interface Upvote {
  approved?: number;
  author?: User;
  id?: number;
  rating?: number;
  recommend?: 'Yes' | 'No';
  review: string;
  title?: string;
  tags: any[];
  created_at?: string;
  user_rating?: any[];
  helpful_count?: number;
}

export interface MeetUp {
  title: string;
  description: string;
  reason: string;
  start_date_time: string;
  end_date_time: string;
  is_private: boolean;
  place_id: number;
  eventId?: number;
}

export interface PostUpvote {
  title?: string;
  rating?: number;
  review: string;
  recommend?: 'Yes' | 'No';
  tags: string[];
}

export interface Offer {
  id: number;
  title: string;
  summary?: string;
  color?: string;
  offer_type: OfferType;
  redemption_value: string;
  points_boost_value: number;
  points_boost: number;
  total_use_limit_per_user: number;
  quantity_available: number;
  use_limit_value: number;
  use_limit_period: string;
  place_id: number;
  event_id?: number;
  article_id?: number;
  partner_id?: number;
  created_at: string;
  updated_at: string;
  redemption_vouchers?: Record<string, string>[];
  lifestyle_groups?: Record<string, string>[];
  topics_of_interest?: TopicOfInterest[];
  place: Place;
  is_bookmarked: boolean;
  points_earning: number;
  points_redeem_amount: number;
  starts_at: string;
  ends_at: string;
  is_geofencing_required?: boolean;
  image_url: string;
  event?: Event;
  quantity_type?: string;
  type?: 'offer';
  countdown_required?: boolean;
  model_type: string;
  is_premium?: boolean;
  readable_status?: string;
  retail_price?: string;
  place_name?: string;
  item_payment_cc?: boolean;
  item_payment_points?: boolean;
  item_payment_pass?: boolean;
  retail_value?: string;
}

export enum OfferType {
  INDIVIDUAL_REDEMPTION = 'INDIVIDUAL_REDEMPTION',
  REDEMPTION = 'REDEMPTION',
  PREMIUM_REDEMPTION = 'PREMIUM_REDEMPTION',
  POINTS_BOOST = 'POINTS_BOOST',
  EVENT_REDEMPTION = 'EVENT_REDEMPTION',
  DISCOUNT = 'DISCOUNT',
}
