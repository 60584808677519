import React from 'react';
import {Center, Flex, Spinner, useTheme, Image, Box} from '@chakra-ui/react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useFetchOffer} from '../../hooks/useOffers';
import Header from './Header';
import Summary from './Summary';
import FallbackImg from '../../assets/images/fallback.jpg';
import BackButton from '../../components/BackButton';
import {useFetchPlace} from '../../hooks/useFetchPlace';
import useGetUserLocation from '../../hooks/useUserLocation';
import {useFetchActivePasses} from '../../hooks/useUserPasses';
import useAuth from '../../hooks/useAuth';
import MediaGallery from '../../components/MediaGallery';
import {useFetchEvent} from '../../hooks/useEvent';
import UserPointProgress from '../../components/UserPointProgress';

const Item = ({onClose}: {onClose?: () => void}) => {
  const theme = useTheme();
  const {itemId} = useParams();
  const [searchParams] = useSearchParams();
  const {data, isLoading} = useFetchOffer(itemId || searchParams.get('id'));
  const userLocation = useGetUserLocation();
  const {user} = useAuth();
  const fetchUserPasses = useFetchActivePasses(!!user);

  const navigate = useNavigate();

  const item = data?.data;

  const fetchPlace = useFetchPlace({
    id: Number(item?.place_id),
    lat: userLocation?.data?.latitude,
    long: userLocation?.data?.longitude,
    enabled: Boolean(item?.place_id) && item?.place_id != 0,
  });

  const fetchEvent = useFetchEvent(item?.event_id);

  const place = item?.place_id
    ? fetchPlace.data?.data
    : item?.event_id
    ? fetchEvent.data?.data?.place
    : null;

  const navigateBack = React.useCallback(() => {
    if (searchParams.has('id') && onClose) {
      onClose();
      return;
    }
    navigate(-1);
  }, []);

  if (isLoading || (item?.place_id && fetchPlace.isLoading) || fetchUserPasses.isLoading) {
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="250px">
        <Flex position="relative" w="100%" justifyContent="space-between">
          <BackButton onClick={navigateBack} m={'.75rem'} pos="relative" />
          <Box m="0.75rem">
            <UserPointProgress user={user} />
          </Box>
        </Flex>
        {item?.media && (
          <MediaGallery
            media={item?.media || [item?.place?.default_media]}
            imageFit="contain"
            shadow={theme.shadows.med}
            galleryButtonBottom="60px"
            imagePaddingBottom={10}
          />
        )}
        {/* <Image
          fallbackStrategy="onError"
          fallbackSrc={FallbackImg}
          src={item.image_url}
          alt={item.item_name}
          position="absolute"
          width="100%"
          height="100%"
          objectFit="contain"
          zIndex="-1"
          borderBottomRadius="14px"
          boxShadow={theme.shadows.med}
        /> */}
      </Flex>
      <Header item={item} place={place} />
      <Summary item={item} />
    </>
  );
};

export default Item;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
